// src/utils/socket.js

let websocket;
let context;
let processor;
let globalStream;

const bufferSize = 4096;
let isRecording = false;

// Store the audio stream for external access
let audioStream = null;

// Function to get the current audio stream
export function getAudioStream() {
    return audioStream;
}

export function initWebSocket() {
    websocket = new WebSocket('wss://wss-einstein.cisp1.io');

    websocket.onopen = () => {
        console.log("WebSocket connection established");
    };
    
    websocket.onclose = event => {
        console.log("WebSocket connection closed", event);
    };

    websocket.onerror = error => {
        console.error("WebSocket error:", error);
    };
}

export function startRecording() {
    if (isRecording) return Promise.resolve(); // Prevent multiple recordings
    isRecording = true;

    const AudioContext = window.AudioContext || window.webkitAudioContext;
    context = new AudioContext();

    // Return the promise so the caller can wait for the stream
    return navigator.mediaDevices.getUserMedia({ audio: true })
        .then(stream => {
            globalStream = stream;
            audioStream = stream; // Store the stream for external access
            const input = context.createMediaStreamSource(stream);
            processor = context.createScriptProcessor(bufferSize, 1, 1);
            processor.onaudioprocess = e => processAudio(e);
            input.connect(processor);
            processor.connect(context.destination);

            sendAudioConfig();
        })
        .catch(error => {
            console.error('Error accessing microphone', error);
            isRecording = false; // Reset recording state on failure
            throw error; // Re-throw to allow handling in the caller
        });
}

export function stopRecording() {
    console.log("Stopping recording...");
    if (!isRecording) return;
    isRecording = false;

    if (globalStream) {
        globalStream.getTracks().forEach(track => track.stop());
        globalStream = null;
    }
    if (processor) {
        processor.disconnect();
        processor = null;
    }
    if (context) {
        context.close().then(() => context = null);
    }

    audioStream = null; // Clear the stored stream
}

function sendAudioConfig() {
    const audioConfig = {
        type: 'config',
        data: {
            sampleRate: context.sampleRate,
            bufferSize: bufferSize,
            channels: 1,
            language: 'portuguese',
            processing_strategy: 'silence_at_end_of_chunk',
            processing_args: {
                chunk_length_seconds: 1.5,
                chunk_offset_seconds: 0.1
            }
        }
    };

    websocket.send(JSON.stringify(audioConfig));
}

function downsampleBuffer(buffer, inputSampleRate, outputSampleRate) {
    if (inputSampleRate === outputSampleRate) {
        return buffer;
    }
    const sampleRateRatio = inputSampleRate / outputSampleRate;
    const newLength = Math.round(buffer.length / sampleRateRatio);
    const result = new Float32Array(newLength);
    let offsetResult = 0;
    let offsetBuffer = 0;
    while (offsetResult < result.length) {
        const nextOffsetBuffer = Math.round((offsetResult + 1) * sampleRateRatio);
        let accum = 0, count = 0;
        for (let i = offsetBuffer; i < nextOffsetBuffer && i < buffer.length; i++) {
            accum += buffer[i];
            count++;
        }
        result[offsetResult] = accum / count;
        offsetResult++;
        offsetBuffer = nextOffsetBuffer;
    }
    return result;
}

function processAudio(e) {
    const inputSampleRate = context.sampleRate;
    const outputSampleRate = 16000; // Target sample rate

    const left = e.inputBuffer.getChannelData(0);
    const downsampledBuffer = downsampleBuffer(left, inputSampleRate, outputSampleRate);
    const audioData = convertFloat32ToInt16(downsampledBuffer);

    if (websocket && websocket.readyState === WebSocket.OPEN) {
        websocket.send(audioData);
    }
}

function convertFloat32ToInt16(buffer) {
    let l = buffer.length;
    const buf = new Int16Array(l);
    while (l--) {
        buf[l] = Math.min(1, buffer[l]) * 0x7FFF;
    }
    return buf.buffer;
}

export {
    websocket
};
