// src/components/AudioVisualizer.js

import React, { useEffect, useRef } from 'react';
import './AudioVisualizer.scss'; // Optional: For styling

export default function AudioVisualizer({ audioStream }) {
    const canvasRef = useRef(null);
    const animationRef = useRef(null);

    console.log("Visualizer AudioStream => ", audioStream);
    useEffect(() => {
        // if (!audioStream) return;
        
        
        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        const source = audioContext.createMediaStreamSource(audioStream);
        const analyser = audioContext.createAnalyser();

        source.connect(analyser);
        analyser.fftSize = 2048;

        const bufferLength = analyser.fftSize;
        const dataArray = new Uint8Array(bufferLength);

        const canvas = canvasRef.current;
        const canvasCtx = canvas.getContext('2d');

        // Resize canvas to fit container
        const resizeCanvas = () => {
            canvas.width = canvas.parentElement.clientWidth;
            canvas.height = 75; // Fixed height or adjust as needed
        };

        resizeCanvas();
        window.addEventListener('resize', resizeCanvas);

        const draw = () => {
            animationRef.current = requestAnimationFrame(draw);

            analyser.getByteTimeDomainData(dataArray);

            canvasCtx.fillStyle = 'rgb(255, 255, 255)';
            canvasCtx.fillRect(0, 0, canvas.width, canvas.height);

            canvasCtx.lineWidth = 1;
            canvasCtx.strokeStyle = 'rgb(0, 0, 0)';

            canvasCtx.beginPath();

            const sliceWidth = (canvas.width * 1.0) / bufferLength;
            let x = 0;

            for (let i = 0; i < bufferLength; i++) {
                const v = dataArray[i] / 128.0;
                const y = (v * canvas.height) / 2;

                if (i === 0) {
                    canvasCtx.moveTo(x, y);
                } else {
                    canvasCtx.lineTo(x, y);
                }

                x += sliceWidth;
            }

            canvasCtx.lineTo(canvas.width, canvas.height / 2);
            canvasCtx.stroke();
        };

        draw();

        return () => {
            cancelAnimationFrame(animationRef.current);
            analyser.disconnect();
            source.disconnect();
            audioContext.close();
            window.removeEventListener('resize', resizeCanvas);
        };
    }, [audioStream]);

    return (
        <canvas
            ref={canvasRef}
            className="audio-visualizer"
        ></canvas>
    );
}
