// src/pages/main/main.js

import React, { useEffect, useState } from "react";
import './main-page.scss';
import MainLayout from "../../layouts/main";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Divider from "../../components/Divider";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import CancelIcon from '@mui/icons-material/Cancel';
import Tooltip from '@mui/material/Tooltip';
import { formatDate } from "../../utils/fomatDate";
import apiBaseUrl from '../../utils/apiConfig';
import useWebsocket from "../../hooks/useWebsocket";
import { CircularProgress } from '@mui/material';
import AudioVisualizer from '../../components/AudioVisualizer'; // Import the visualizer

export default function MainPage() {
    const [micDisabled, setMicDisabled] = useState(true);
    const [age, setAge] = useState('');
    const [gender, setGender] = useState('');
    const [isPrinting, setIsPrinting] = useState(false);
    const { isRecording, transcription, finalTranscription, startRecording, stopRecording, audioStream, error } = useWebsocket(); // Destructure audioStream and error

    const [isFirstFetch, setIsFirstFetch] = useState(true);
    const [hpma, setHpma] = useState("");
    const [isda, setIsda] = useState("");
    const [alergias, setAlergias] = useState([]);
    const [conduta, setConduta] = useState("");
    const [exameFisico, setExameFisico] = useState("");
    const [medicamentosAdministrados, setMedicamentosAdministrados] = useState([]);
    const [medicamentosPrescritos, setMedicamentosPrescritos] = useState([]);
    const [queixaDuracao, setQueixaDuracao] = useState("");
    const [sintomas, setSintomas] = useState([]);
    const [updatedTranscription, setUpdatedTranscription] = useState();
    const [loading, setLoading] = useState(false);


    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const onInputChange = ({ target }) => {
        const name = target.name;
        const value = target.value;

        eval(`set${capitalizeFirstLetter(name)}`)(value);
    }

    const printDiagnose = () => {
        setIsPrinting(true);
        window.scroll({ top: 0, behavior: 'smooth' });
        setTimeout(() => {
            window.print();
        }, 1000);
    }

    const stopAndStructure = () => {
        structureData();
        stopRecording();
    }

    const structureData = () => {
        if (finalTranscription !== '') {
            setIsFirstFetch(false);
            setLoading(true);
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ transcription: finalTranscription, age: age, gender: gender })
            };

            fetch(`${apiBaseUrl}/structured_terms`, requestOptions)
                .then(response => response.json())
                .then(data => {
                    setHpma(data.HPMA || "");
                    setIsda(data.ISDA || "");
                    setAlergias(data.alergias || []);
                    setConduta(data.conduta || "");
                    setExameFisico(data.exame_fisico || "");
                    setMedicamentosAdministrados(data.medicamentos?.administrados || []);
                    setMedicamentosPrescritos(data.medicamentos?.prescritos || []);
                    setQueixaDuracao(data.queixa_duracao || "");
                    setSintomas(data.sintomas || "");
                    setUpdatedTranscription(finalTranscription);

                })
                .catch((err) => {
                    console.error(err);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }

    const renderCallButton = () => {
        if (micDisabled) {
            return (
                <Tooltip title="O preenchimento dos campos sexo e idade são obrigatórios para que o botão de gravação seja habilitado." placement="left" arrow>
                    <span>
                        <IconButton color="primary" disabled={micDisabled} className="call-icon-container">
                            <KeyboardVoiceIcon style={{ fontSize: "24px" }} />
                        </IconButton>
                    </span>
                </Tooltip>
            );
        }

        return (
            <IconButton color="primary" disabled={micDisabled} className="call-icon-container" onClick={startRecording}>
                <KeyboardVoiceIcon style={{ fontSize: "24px" }} />
            </IconButton>
        );
    }

    useEffect(() => {
        if (age && gender) {
            setMicDisabled(false);
            return;
        }

        setMicDisabled(true);
    }, [age, gender]);

    useEffect(() => {
        window.onafterprint = () => setIsPrinting(false);
    }, []);

    return (
        <MainLayout>
            <Grid container className="results-section" direction="column" gap={6}>
                <Card className="card">
                    <CardContent className="content">
                        <Grid container className="title" alignItems="center" justifyContent="space-between">
                            <Typography variant="h5" component="h3"><strong>Informações do Paciente</strong></Typography>
                            {isRecording ?
                                <IconButton color="primary" className="cancel-icon-container" onClick={stopAndStructure}>
                                    <CancelIcon style={{ fontSize: "24px" }} />
                                </IconButton>
                                :
                                renderCallButton()

                            }
                        </Grid>
                        <Box my={2} />
                        <Divider />
                        <Box py={2} />
                        <Grid container gap={2}>
                            <Grid container justifyContent="space-between" gap={6}>
                                <Grid item style={{ flexGrow: 1, flexShrink: 1, minWidth: '200px' }}>
                                    <TextField
                                        id="name"
                                        label="Nome"
                                        variant="standard"
                                        style={{ width: '100%' }}
                                    />
                                </Grid>
                                <Grid item style={{ flexGrow: 1, flexShrink: 1, minWidth: '200px' }}>
                                    <FormControl variant="standard" style={{ width: '100%' }}>
                                        <InputLabel id="demo-simple-select-standard-label">Sexo</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            value={gender}
                                            name="gender"
                                            onChange={onInputChange}
                                        >
                                            <MenuItem value="female">Feminino</MenuItem>
                                            <MenuItem value="male">Masculino</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item style={{ flexGrow: 1, flexShrink: 1, minWidth: '200px' }}>
                                    <TextField
                                        id="age"
                                        label="Idade"
                                        variant="standard"
                                        type="number"
                                        value={age}
                                        name="age"
                                        onChange={onInputChange}
                                        style={{ width: '100%' }}
                                    />
                                </Grid>
                                <Grid item style={{ flexGrow: 1, flexShrink: 1, minWidth: '200px' }}>
                                    <TextField
                                        id="date"
                                        label="Data"
                                        variant="standard"
                                        disabled
                                        value={formatDate(new Date())}
                                        style={{ width: '100%' }}
                                    />
                                </Grid>
                            </Grid>
                            {/* <Grid container className="relato-paciente-container">
                                <TextField
                                    label="Relato do Paciente"
                                    multiline
                                    variant="standard"
                                    value={finalTranscription}
                                    disabled
                                />
                            </Grid> */}
                        </Grid>
                        {/* Add the AudioVisualizer here */}
                        {isRecording && audioStream && (
                            <Box mt={4}>
                                <AudioVisualizer audioStream={audioStream} />
                            </Box>
                        )}
                        {/* Optional: Display errors */}
                        {error && (
                            <Box mt={2}>
                                <Typography variant="body2" color="error">
                                    Erro ao acessar o microfone: {error.message}
                                </Typography>
                            </Box>
                        )}
                    </CardContent>
                </Card>

                <Card className="card">
                    <CardContent className="content">
                        <Grid container direction="column" gap={2}>
                            <Grid item className="title" justifyContent="space-between">
                                <Typography variant="h5" component="h3"><strong>Sumário da Consulta</strong></Typography>
                            </Grid>
                            <Divider />
                            <Box my={1} />
                            {!isFirstFetch ?
                                loading ?
                                    <Box display="flex" justifyContent="center" alignItems="center" width="100%"><CircularProgress /></Box> :
                                    <Grid container>

                                        {queixaDuracao && (
                                            <Grid item xs={12}>
                                                <Typography variant="h6"><strong>Queixa e Duração</strong></Typography>
                                                <Box my={1} />
                                                <Typography variant="body2">{queixaDuracao}</Typography>
                                                <Box my={5} />
                                            </Grid>
                                        )}

                                        {hpma && (
                                            <Grid item xs={12}>
                                                <Typography variant="h6"><strong>HPMA</strong></Typography>
                                                <Box my={1} />
                                                <Typography variant="body2">{hpma}</Typography>
                                                <Box my={5} />
                                            </Grid>
                                        )}

                                        {isda && (
                                            <Grid item xs={12}>
                                                <Typography variant="h6"><strong>ISDA</strong></Typography>
                                                <Box my={1} />
                                                <Typography variant="body2">{isda}</Typography>
                                                <Box my={5} />
                                            </Grid>
                                        )}

                                        {exameFisico && (
                                            <Grid item xs={12}>
                                                <Typography variant="h6"><strong>Exame Físico</strong></Typography>
                                                <Box my={1} />
                                                <Typography variant="body2">{exameFisico}</Typography>
                                                <Box my={5} />
                                            </Grid>
                                        )}

                                        {conduta && (
                                            <Grid item xs={12}>
                                                <Typography variant="h6"><strong>Conduta</strong></Typography>
                                                <Box my={1} />
                                                <Typography variant="body2">{conduta}</Typography>
                                                <Box my={5} />
                                            </Grid>
                                        )}

                                        {alergias.length > 0 && (
                                            <Grid item xs={12}>
                                                <Typography variant="h6"><strong>Alergias</strong></Typography>
                                                <Box my={1} />
                                                {alergias.map((alergia, index) => (
                                                    <Typography key={index} variant="body2">{alergia}</Typography>
                                                ))}
                                                <Box my={5} />
                                            </Grid>
                                        )}

                                        {/* {sintomas.length > 0 && (
                                            <Grid item xs={12}>
                                                <Typography variant="h6"><strong>Sintomas</strong></Typography>
                                                <Box my={1} />
                                                {sintomas.map((sintoma, index) => (
                                                    <Typography key={index} variant="body2">{sintoma}</Typography>
                                                ))}
                                                <Box my={5} />
                                            </Grid>
                                        )} */}

                                        {medicamentosAdministrados.length > 0 && (
                                            <Grid item xs={12}>
                                                <Typography variant="h6"><strong>Medicamentos Administrados</strong></Typography>
                                                <Box my={1} />
                                                {medicamentosAdministrados.map((medicamento, index) => (
                                                    <Typography key={index} variant="body2">{medicamento}</Typography>
                                                ))}
                                                <Box my={5} />
                                            </Grid>
                                        )}

                                        {medicamentosPrescritos.length > 0 && (
                                            <Grid item xs={12}>
                                                <Typography variant="h6"><strong>Medicamentos Prescritos</strong></Typography>
                                                <Box my={1} />
                                                {medicamentosPrescritos.map((medicamento, index) => (
                                                    <Typography key={index} variant="body2">{medicamento}</Typography>
                                                ))}
                                                <Box my={5} />
                                            </Grid>
                                        )}

                                    </Grid> : ''}
                        </Grid>
                    </CardContent>
                </Card>

                
                {updatedTranscription && 
                    <Card className="card">
                        <CardContent className="content">
                            <Grid container direction="column" gap={2}>
                                <Grid item className="title" justifyContent="space-between">
                                    <Typography variant="h5" component="h3"><strong>Transcrição</strong></Typography>
                                </Grid>
                                <Divider />
                                <Box my={1} />
                                <Typography variant="body2">{updatedTranscription}</Typography>
                            </Grid>
                        </CardContent>
                    </Card>
                }

                <Grid container direction="column" gap={6}>
                    <Grid item style={{ width: '100%', maxWidth: '1400px', margin: '0 auto' }}>
                        {!isPrinting &&
                            <Button variant="contained" onClick={printDiagnose} fullWidth>Imprimir Relatório</Button>
                        }
                    </Grid>
                </Grid>

            </Grid>
        </MainLayout>
    )
}
